@font-face {
  font-family: "ReenieBeanie";
  src: local("ReenieBeanie"),
   url("./assets/fonts/Reenie_Beanie/ReenieBeanie-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "ReadexPro";
  src: local("ReadexPro"),
   url("./assets/fonts/Readex_Pro/static/ReadexPro-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Playfair";
  src: local("Playfair"),
   url("./assets/fonts/Playfair_Display_SC/PlayfairDisplaySC-Regular.ttf") format("truetype");
}


body {
  margin: 0;
  font-family: 'Playfair';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
